import { render, staticRenderFns } from "./Quests.vue?vue&type=template&id=08a9d960&scoped=true&"
import script from "./Quests.vue?vue&type=script&lang=ts&"
export * from "./Quests.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a9d960",
  null
  
)

export default component.exports