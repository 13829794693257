


































































































import {Component, Vue} from "vue-property-decorator";
import Aside from "@/components/Aside.vue";
import QuestListItem from "@/components/QuestListItem.vue";
import QuestReward from "@/components/QuestReward.vue";

@Component({
    name: "Quests",
    components:{ Aside, QuestListItem, QuestReward}
})
export default class Quests extends Vue {
    protected isOpen = true;

    openCloseMenu() {
        this.isOpen = !this.isOpen;
    }
}
